import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import TableView from "../../screens/Admin/Releases/components/TableView";
import SelectComponent from "../form/SelectComponent";
import Switch from "../Switch";
import { GlobalFilter } from "./ReleasesAdmin";
import { useQuery, useQueryClient } from "react-query";
import { getUserBalances } from "../../services/admin/transactions";
import { periods } from "./periods";

const INITIAL_PAGE_SIZE = 10;

const monthMap = {
  1: "Janurary",
  2: "Feburary",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const periodOptions = [
  { label: "All", value: null },
  ...periods.map((p) => ({
    label: ` ${monthMap[new Date(p.endDate.$date).getMonth() + 1]}, ${new Date(
      p.endDate.$date
    ).getFullYear()}`,
    value: p._id.$oid,
  })),
];

const PeriodFilterOptions = ({ setSelectedPeriodId, selectedPeriodId }) => {
  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 col-lg-2 py-2 py-md-0 text-white"
      }
      id={"month"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">Period</div>
      <SelectComponent
        value={periodOptions.find((o) => o.value === selectedPeriodId) || ""}
        extendOptionWidth
        id={"month"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(periodOptions)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(
          val,
          databaseField,
          commentField,
          databaseKey,
          type
        ) => {
          if (type.action !== "input-change") setSelectedPeriodId(val);
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

function formatAmount(value) {
  if (value < 0) {
    return <div className="text-danger">- {Math.abs(value).toFixed(2)}</div>;
  } else if (value === 0) {
    return <div> € {Math.abs(value)}</div>;
  } else if (value > 0) {
    return <div className="textColorPrimary">+ {value.toFixed(2)}</div>;
  }
}

const UserBalanceAdmin = () => {
  const [selectedPeriodId, setSelectedPeriodId] = useState(null);

  const { data: userBalancesData, isLoading } = useQuery(
    ["userbalances", selectedPeriodId],
    () => getUserBalances(selectedPeriodId),
    { refetchOnWindowFocus: false }
  );

  const balances = useMemo(
    () => userBalancesData?.data ?? [],
    [userBalancesData?.data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
      },
      {
        Header: "Name",
        accessor: (originalRow) => originalRow,
        Cell: ({ value, row }) =>
          `${value?.user?.firstname ?? ""} ${
            value?.user?.lastname ?? ""
          }`.trim(),
      },
      {
        Header: "User",
        accessor: "user.email",
      },
      {
        Header: "Total Earned",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) =>
          formatAmount(
            Math.abs(value.creditTotal) + Math.abs(value.debitTotal)
          ),
      },

      {
        Header: "Total Collected",
        accessor: "totalCollected",
        Cell: ({ value }) => formatAmount(value ?? 0),
      },

      {
        Header: "Total Pending",
        accessor: "totalPending",
        Cell: ({ value }) => formatAmount(value ?? 0),
      },

      {
        Header: "Total Credited",
        accessor: "creditTotal",
        Cell: ({ value }) => formatAmount(value ?? 0),
      },

      {
        Header: "Total Debited",
        accessor: "debitTotal",
        Cell: ({ value }) => formatAmount(value ?? 0),
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: ({ value }) => <b>{value.toFixed(2)}</b>,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: balances?.data ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  const total = (balances?.data ?? []).reduce(
    (acc, curr) => acc + curr.balance,
    0
  );
  const totalCredited = (balances?.data ?? []).reduce(
    (acc, curr) => acc + curr.creditTotal,
    0
  );
  const totalDebited = (balances?.data ?? []).reduce(
    (acc, curr) => acc + curr.debitTotal,
    0
  );

  const totalCollected = (balances?.data ?? []).reduce(
    (acc, curr) => acc + curr.totalCollected,
    0
  );

  const totalReported = (balances?.data ?? []).reduce(
    (acc, curr) => acc + curr.creditTotal + curr.debitTotal,
    0
  );

  const totalPending = (balances?.data ?? []).reduce(
    (acc, curr) => (acc + curr.totalPending ? curr.totalPending : 0),
    0
  );

  return (
    <main className={"admin-releases mt-4 pb-3 "}>
      <div className="d-block mb-3" style={{ zIndex: 1 }}>
        <p className="font-weight-bold text-white mb-0">Filter by:</p>
        <div className="row pb-2 align-items-center justify-content-between">
          <div className="col-12 col-md">
            <div className="row">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              <PeriodFilterOptions
                selectedPeriodId={selectedPeriodId}
                setSelectedPeriodId={setSelectedPeriodId}
              />
            </div>
          </div>
        </div>
      </div>
      <TableView
        tableInstance={tableInstance}
        loading={isLoading}
        total={total}
        totalCredited={totalCredited}
        totalDebited={totalDebited}
        adminUserBalances={true}
        totalCollected={totalCollected}
        totalReported={totalReported}
        totalPending={totalPending}
      />
    </main>
  );
};

export default UserBalanceAdmin;
