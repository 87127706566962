import React from "react";
import {
  Pagination,
  TableBody,
  TableHeader,
} from "../../../../components/Table";

const TableView = ({
  tableInstance,
  loading,
  salesData,
  totalNewSales,
  totalNewStreams,
  upc,
  isrc,
  checkbox,
  adminUserBalances,
  total,
  totalDebited,
  totalCredited,
  totalCollected,
  totalReported,
  totalPending,
  handleSort,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <div className="dashboard-airplay releases">
      <table {...getTableProps()} className="airplay-table">
        <TableHeader headerGroups={headerGroups} handleSort={handleSort} />
        <TableBody
          numberOfColumns={7}
          loading={loading}
          page={page}
          prepareRow={prepareRow}
          getTableBodyProps={getTableBodyProps}
        />
        {salesData && (
          <>
            {checkbox && <td></td>}
            <td className="p-3  text-left">
              <b>Total</b>
            </td>
            {upc && <td></td>}
            {isrc && <td></td>}

            <td className="p-3 text-left">{totalNewStreams}</td>

            <td className="p-3 text-left">
              <b>€ {totalNewSales}</b>
            </td>
          </>
        )}
        {adminUserBalances && (
          <>
            <td className="p-3  text-left">
              <b>Total</b>
            </td>
            <td></td>
            <td></td>
            {totalReported && (
              <td className="p-3  text-left">
                <b> {totalReported.toFixed(2)}</b>
              </td>
            )}
            {totalCollected && (
              <td className="p-3  text-left">
                <b> {totalCollected.toFixed(2)}</b>
              </td>
            )}
            {totalPending && (
              <td className="p-3  text-left">
                <b> {totalPending.toFixed(2)}</b>
              </td>
            )}

            {totalCredited && (
              <td className="p-3  text-left">
                <b> {totalCredited.toFixed(2)}</b>
              </td>
            )}
            {totalDebited && (
              <td className="p-3  text-left">
                <b>{totalDebited.toFixed(2)}</b>
              </td>
            )}
            {total && (
              <td className="p-3  text-left">
                <b> {total.toFixed(2)}</b>
              </td>
            )}
          </>
        )}
      </table>

      {/* Pagination */}
      <div
        className="row justify-content-center mx-1 mt-3 position-sticky p-3"
        style={{ bottom: 0, background: "#1d2025" }}
      >
        <Pagination
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
};

export default TableView;
