import moment from "moment";
import { autofixSingle } from "../../../redux/actions/distributionActions";
import { flattenParticipantsCopy } from "../helpers/distrHelpers";
import { cloneDeep } from "lodash";
import { VersionFieldRestrictedWords } from "../../../shared/VersionFieldValidation";

// NOTE: these helper functions are used in distribution new release flow, but also in the payout modal

export function valUpc(value, checked) {
  let regex = RegExp("^[0-9]*$");
  return (
    ((value?.length === 12 || value?.length === 13) && regex.test(value)) ||
    checked.releaseUpcCodeCheckbox ||
    value === "auto"
  ); // if checkbox checked validation is omitted
}

export function valPhoneNumber(value) {
  let regex = RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$");
  return regex.test(value);
}

export function valDateOfBirth(value) {
  let regex = RegExp(
    "((0[1-9])|([1-2][0-9])|(3[0-1]))[.](([0][1-9])|([0][1-9])|(1[0-2]))[.]((19[2-9][0-9])|(20[0-9][0-9]))"
  );
  return regex.test(value);
}

export function valCnum(value, checked) {
  return (
    (value?.length <= 45 && value.length > 0) ||
    checked.catalogNumberCheckbox ||
    value === "auto"
  );
}

export function valYear(value) {
  let regex = RegExp("^[0-9]*$");
  return value?.length === 4 && regex.test(value);
}

export function valDigRel(value, valMessages, setValMessages, form) {
  if (form["#original_release"]) {
    var valueIsCorrect =
      value && moment(value).isAfter(form["#original_release"]); // validates if date is before the previous release
    var valMsg = "Release date cannot be before the previous release date";
  } else {
    valueIsCorrect = value ? true : false;
    valMsg = "Please provide a date for your release";
  }

  setValMessages((prevValMessages) => ({
    ...prevValMessages,
    "#digital_release": !valueIsCorrect ? valMsg : "",
  }));

  return valueIsCorrect;
}

export function valOrRel(value, prSwitch, valMessages, setValMessages, form) {
  if (prSwitch.previouslyReleased) {
    if (form["#digital_release"]) {
      var valueIsCorrect =
        value && moment(value).isBefore(form["#digital_release"]); // validates if date is after the digital release
      var valMsg = "Previous release date cannot be after the release date";
    } else {
      valueIsCorrect = value ? true : false;
      valMsg = "Please provide a date for your release";
    }

    setValMessages((prevValMessages) => ({
      ...prevValMessages,
      "#original_release": !valueIsCorrect ? valMsg : "",
    }));
    return valueIsCorrect;
  } else {
    return true; // if field hidden
  }
}

export function valCover(value) {
  if (value === "initial" || value === "error") {
    // means the image has not yet been uploaded or there has been an error
    var valueIsCorrect = false;
  } else {
    // means the image is in the client (value = "ready") or is preloaded (value = [image url])
    valueIsCorrect = true;
  }

  return valueIsCorrect;
}

export function valIsrc(value) {
  return value?.length === 12 || value === "auto"; // if checkbox checked validation is omitted
}

export function valIswc(value) {
  return value?.length >= 11 || value?.length === 0; // if checkbox checked validation is omitted
}

export function valEmail(value, databaseKey, inputId) {
  let regex = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regex.test(value) || value.length === 0;
}

// this function will return true if regex doesn't match (used in my money page)
export function valEmailRegex(value) {
  let regex = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return !regex.test(value);
}

export function valShare(value) {
  let regex = RegExp("^[0-9]*$");
  return (regex.test(value) && value >= 0 && value <= 100) || !value;
}

export function valGeneralShareAmount(generalShareAmount) {
  // for validating if shares equal to 100% (if present)
  return generalShareAmount > 100;
}

export function val(value) {
  return value ? true : false;
}

// an inversion of the function above
export function fieldRequired(value) {
  return value?.length && value !== undefined ? false : true;
}

export function valTitle(value) {
  const notAllowed = [
    "<",
    ">",
    "|",
    "/",
    "-",
    ".",
    ":",
    ";",
    "!",
    "¡",
    "?",
    "¿",
    "-",
    "·",
    "&",
    '"',
    "‘",
  ];
  // blocking the non allowed characters, as well as names that contain only uppercase / lowercase letters

  const notAllowedCheck =
    notAllowed
      .map((character) => value.includes(character))
      .filter((item) => item === true).length > 0
      ? false
      : true;
  const titleIsEmpty = value.length !== 0;

  return ![notAllowedCheck, titleIsEmpty].includes(false);
}

export function valVersion(version) {
  const restrictedWords = VersionFieldRestrictedWords.map((word) =>
    word.toLowerCase()
  );

  const notAllowedCheck = version
    .split(" ")
    .some((versionWord) =>
      restrictedWords.includes(versionWord?.trim()?.toLowerCase())
    );

  if (notAllowedCheck) {
    return false;
  } else if (version.toLowerCase().includes("mix")) {
    if (version.split(" ")[0].toLowerCase() === "mix") {
      return false;
    } else {
      return true;
    }
  } else if (version.toLowerCase().includes("edit")) {
    if (version.split(" ")[0].toLowerCase() === "edit") {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function valSecondaryGenre(secondaryGenreValue, primaryGenreValue) {
  return secondaryGenreValue !== primaryGenreValue;
}

// special validation function for validating releases which contain only one track (singles) - form must match the release form
export function validateSingleUT(trackForm, releaseForm) {
  const track = flattenParticipantsCopy(trackForm);
  const release = flattenParticipantsCopy(releaseForm);

  const checkFormMatchesReleaseFailing =
    track["#track_title"] !== release["#title"] ||
    track["#remix_or_version"] !== release["#remix_or_version"] ||
    track["#primary_genre"] !== release["#primary_genre"] ||
    track["#secondary_genre"] !== release["#secondary_genre"] ||
    track["#language"] !== release["#language"] ||
    track["#explicit_lyrics"] !== release["#explicit_lyrics"] ||
    track["#p_year"] !== release["#p_year"] ||
    track["#p_line"] !== release["#p_line"]; // if true, validation doesn't pass

  const participantsCheck = (releaseItem, trackItem) =>
    (Object.keys(releaseItem).includes(trackItem.type) &&
      Object.values(releaseItem).includes(trackItem.name)) ||
    (releaseItem.type === trackItem.type &&
      releaseItem.value === trackItem.name);

  const checkParticipantsFailing = track["#participants"]
    .map((trackItem) => {
      return release["#participants"]
        .map((releaseItem) => participantsCheck(releaseItem, trackItem))
        .includes(true);
    })
    .includes(false); // if true, means validation not passing
  const checkParticipantsFailingReverse = release["#participants"]
    .map((releaseItem) => {
      return track["#participants"]
        .map((trackItem) => participantsCheck(releaseItem, trackItem))
        .includes(true);
    })
    .includes(false); // if true, means validation not passing

  return (
    checkFormMatchesReleaseFailing ||
    checkParticipantsFailing ||
    checkParticipantsFailingReverse
  );
}

export function autofixSingleHelper(singleForm, releaseData, userEmail) {
  releaseData = flattenParticipantsCopy(releaseData);
  singleForm = cloneDeep(singleForm);
  singleForm["#track_title"] = releaseData["#title"];
  singleForm["#explicit_lyrics"] = releaseData["#explicit_lyrics"];
  singleForm["#language"] = releaseData["#language"];
  singleForm["#p_line"] = releaseData["#p_line"];
  singleForm["#p_year"] = releaseData["#p_year"];
  singleForm["#primary_genre"] = releaseData["#primary_genre"];
  singleForm["#secondary_genre"] = releaseData["#secondary_genre"];
  singleForm["#remix_or_version"] = releaseData["#remix_or_version"];

  // populate primary artists
  singleForm["#participants"].primary = {};
  let releasePrimary = releaseData["#participants"].filter(
    (item) => Object.keys(item)[0] === "primary"
  );
  let newPrimary = [];
  releasePrimary.map((item, index) => {
    newPrimary.push({
      type: "primary",
      name: item.primary,
      email: index === 0 ? userEmail : "",
      share: "",
    });
  });
  singleForm["#participants"].primary = newPrimary;
  // populate contributors
  singleForm["#participants"].contributors = {};
  let releaseContributors = releaseData["#participants"].filter(
    (item) => Object.keys(item)[0] !== "primary"
  );
  let newContributors = [];
  releaseContributors.map((item) => {
    newContributors.push({
      type: item.type,
      name: item.value,
      email: "",
      share: "",
    });
  });
  singleForm["#participants"].contributors = newContributors;
  return singleForm;
}

export const valContributors = (
  contributors,
  primary,
  valMessages,
  setValMessages
) => {
  // if (!contributors.length || contributors[0].value === "") {
  //   setValMessages((prevValMessages) => ({
  //     ...prevValMessages,
  //     "#participants.contributors": "please provide atleast one contributor",
  //   }));
  //   return false;
  // }

  // if (contributors[0].type === "") {
  //   setValMessages((prevValMessages) => ({
  //     ...prevValMessages,
  //     "#participants.contributors": "please choose a contributor type",
  //   }));
  //   return false;
  // }
  const composerPresent = contributors.some(
    (contributor) => contributor.type === "composer"
  );


  const producerPresent = contributors.some(
    (contributor) => contributor.type === "producer"
  );

  if (!contributors?.length || !producerPresent) {
    setValMessages((prevValMessages) => ({
      ...prevValMessages,
      "#participants.contributors": "Provide at least one producer. Use the full name.",
    }));
    return false;
  }

  if (!contributors?.length || !composerPresent) {
    setValMessages((prevValMessages) => ({
      ...prevValMessages,
      "#participants.contributors": "Provide at least one composer. Use the full name.",
    }));
    return false;
  }

  if (
    composerPresent &&
    contributors.find((contributor) => contributor.type === "composer")
      .value === ""
  ) {
    setValMessages((prevValMessages) => ({
      ...prevValMessages,
      "#participants.contributors": "Provide at least one composer. Use the full name.",
    }));
    return false;
  }

  for (let i = 0; i < primary?.length; i++) {
    for (let j = 0; j < contributors?.length; j++) {
      if (
        (contributors[j].type === "performer" ||
          contributors[j].type === "producer" ||
          contributors[j].type === "featuring") &&
        contributors[j]?.value?.trim().toLowerCase() ===
          primary[i]?.primary?.trim().toLowerCase()
      ) {
        setValMessages((prevValMessages) => ({
          ...prevValMessages,
          "#participants.contributors":
            "performer, producer or featuring should not be same as primary artist",
        }));
        return false;
      }
    }
  }

  return true;
};

export const trackValContributors = (contributors, trackType) => {
  const lyricistPresent = contributors.some(
    (contributor) => contributor.type === "lyricist"
  );

  if (trackType === "lyrics" && !lyricistPresent) {
    return false;
  }

  if (lyricistPresent) {
    const lyricist = contributors.find(
      (contributor) => contributor.type === "lyricist"
    );
    if (lyricist.name.trim() === "") {
      return false;
    }
  }

  return true;
};
