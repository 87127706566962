import {
  sevendigital,
  amazon,
  anghami,
  audible,
  awa,
  boomplay,
  claromusica,
  deezer,
  facebook,
  gracenote,
  iheart,
  imi,
  itunes,
  jaxsta,
  jiosaavn,
  kdigital,
  kkbox,
  mixcloud,
  monkingme,
  netease,
  pandora,
  resso,
  rhapsodynapster,
  shazam,
  soundcloud,
  spotify,
  tencent,
  tidal,
  tiktok,
  uma,
  aligenie,
  yandex,
  ytcontentid,
  ytmusic,
  zvooq,
  twitch,
  beatport,
  joox,
  juno,
  kanjian,
  adaptr,
  peloton,
  soundexchange,

  flokr,
  kuaishou,
  qobuz,
  vol,
  tglobal,
  trebel,
  pjm,
  xiam,
  supnat,


} from "../../assets/platforms";

const platformIcon = {
  "7 Digital": { "src": sevendigital, "alt": "sevendigital" },
  "Adaptr": { "src": adaptr, "alt": "adaptr" },
  "AliGenie": { "src": aligenie, "alt": "aligenie" },
  "Amazon Music": { "src": amazon, "alt": "amazon" },
  "Anghami": { "src": anghami, "alt": "anghami" },
  "Audible Magic": { "src": audible, "alt": "audible" },
  "AWA": { "src": awa, "alt": "awa" },
  "Beatport": { "src": beatport, "alt": "beatport" },
  "Boomplay": { "src": boomplay, "alt": "boomplay" },
  "Claro música": { "src": claromusica, "alt": "claromusica" },
  "Deezer": { "src": deezer, "alt": "deezer" },
  "Facebook Rights Manager": { "src": facebook, "alt": "facebook" },
  "Flo": { "src": flokr, "alt": "flokr" },
  "Gracenote": { "src": gracenote, "alt": "gracenote" },
  "iHeart": { "src": iheart, "alt": "iheart" },
  "IMI Mobile": { "src": imi, "alt": "imi" },
  "iTunes": { "src": itunes, "alt": "itunes" },
  "Jaxsta": { "src": jaxsta, "alt": "jaxsta" },
  "JioSaavn": { "src": jiosaavn, "alt": "jiosaavn" },
  "JOOX": { "src": joox, "alt": "joox" },
  "Juno": { "src": juno, "alt": "juno" },
  "Kanjian": { "src": kanjian, "alt": "kanjian" },
  "KDigital Media": { "src": kdigital, "alt": "kdigital" },
  "Kkbox": { "src": kkbox, "alt": "kkbox" },
  "Kuaishou": { "src": kuaishou, "alt": "kuaishou" },
  "MixCloud": { "src": mixcloud, "alt": "mixcloud" },
  "MonkingMe": { "src": monkingme, "alt": "monkingme" },
  "NetEase Cloud Music": { "src": netease, "alt": "netease" },
  "Pandora": { "src": pandora, "alt": "pandora" },
  "Peloton": { "src": peloton, "alt": "peloton" },
  "Qobuz": { "src": qobuz, "alt": "qobuz" },
  "Resso": { "src": resso, "alt": "resso" },
  "Rhapsody/Napster": { "src": rhapsodynapster, "alt": "rhapsodynapster" },
  "Shazam": { "src": shazam, "alt": "shazam" },
  "Soundcloud": { "src": soundcloud, "alt": "soundcloud" },
  "Soundexchange": { "src": soundexchange, "alt": "soundx" },
  "Spotify": { "src": spotify, "alt": "spotify" },
  "Supernatural": { "src": supnat, "alt": "supnat" },
  "Taobao": { "src": xiam, "alt": "xiam" },
  "Tencent": { "src": tencent, "alt": "tencent" },
  "Tidal": { "src": tidal, "alt": "tidal" },
  "TikTok": { "src": tiktok, "alt": "tiktok" },
  "TikTok Music / Resso": { "src": pjm, "alt": "pjm" },
  "Trebel": { "src": trebel, "alt": "trebel" },
  "Tuned Global": { "src": tglobal, "alt": "tglobal" },
  "Twitch": { "src": twitch, "alt": "twitch" },
  "United Media Agency (UMA)": { "src": uma, "alt": "uma" },
  "Volumo": { "src": vol, "alt": "vol" },
  "Yandex": { "src": yandex, "alt": "yandex" },
  "YouTube Audio Content ID": { "src": ytcontentid, "alt": "ytcontentid" },
  "YouTube Music": { "src": ytmusic, "alt": "ytmusic" },
  "Zvooq": { "src": zvooq, "alt": "zvooq" },
};

export default platformIcon;

export const deletePlatforms = [
  "imi",
  "monkingme",
  "uma",
  "aligenie",
  "yandex",
  "zvooq",
  "juno",
];
