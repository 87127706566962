import React, { useEffect, useState } from "react";
import {
  adminGetUserSubscriptionStatus,
  getAllUsers,
  setFeePercentage,
  setImpersonate,
  setUserMsg,
} from "../../routes/admin";
import InputComponent from "../form/InputComponent";
import SearchIcon from "../../assets/icons/search.svg";

import "./admin.scss";
import { getJWT } from "../utils";
import Toast from "../Toast";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const DEFAULT_PERCENTAGE = 15;

function UsersAdmin() {
  const jwtToken = getJWT();
  const [showToast, setShowToast] = useState(initialToastState);

  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [mainUsersList, setMainUsersList] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [mainUsersList]);

  const fetchUsers = async () => {
    try {
      const res = await getAllUsers(jwtToken);
      setMainUsersList(res.data);
    } catch (e) {
      setShowToast({
        open: true,
        message: e.response?.data?.message
          ? e.response.data.message
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
    }
  };

  async function handleSet(event, userEmail) {
    const email = userEmail;
    const msg = event.target.previousSibling.previousSibling.value;
    const type = event.target.previousSibling.value;

    try {
      await setUserMsg(jwtToken, email, msg, type);

      setTimeout(fetchUsers, 700);
    } catch (error) {
      setShowToast({
        open: true,
        message: error.response.data.message
          ? error.response.data.message
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
    }
  }

  async function handleReset(event, userEmail) {
    const email = userEmail;

    try {
      await setUserMsg(jwtToken, email, "", "");

      setTimeout(fetchUsers, 700);
    } catch (error) {
      setShowToast({
        open: true,
        message: error.response.data.message
          ? error.response.data.message
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
    }
  }

  async function handleImpersonate(user) {
    let answer = window.confirm(
      `Are you sure you want to impersonate ${user.email}?`
    );
    if (answer) {
      const result = await setImpersonate(user.email);
        setShowToast({
          open: true,
          message: `Impersonate succesfull`,
          type: "success",
        });
        document.cookie = `jwtToken=${result.data.token}; path=/; max-age=${
          24 * 60 * 60
        }; domain=${global.config.ssoAppDomain}; secure`;
    }
  }

  async function handleSetFeePercentage(user, feePercentage) {
    let answer = window.confirm(
      `Are you sure you want to change the fee from ${
        user?.feePercentage || DEFAULT_PERCENTAGE
      }% to ${feePercentage}% to ${user.email}?`
    );
    if (answer) {
      try {
        await setFeePercentage(user.email, feePercentage);
        setShowToast({
          open: true,
          message: `Fee percentage updated to ${feePercentage}% for ${user.email}`,
          type: "success",
        });
        setTimeout(fetchUsers, 700);
      } catch (error) {
        setShowToast({
          open: true,
          message: error.response.data.message
            ? error.response.data.message
            : "An unexpected error occured. Please try again.",
          type: "danger",
        });
      }
    }
  }

  const MessageSetter = ({ user, handleSet }) => (
    <div>
      <input className="adminMessages-userMessageInput"></input>
      <select>
        <option>Information</option>
        <option>Blocking</option>
      </select>
      <button
        onClick={(event) => handleSet(event, user.email)}
        style={{ float: "right" }}
      >
        Set
      </button>
    </div>
  );

  const MessageDisplay = ({ user, handleReset }) => (
    <div
      style={
        user.userMsgType === "Blocking" ? { color: "red" } : { color: "yellow" }
      }
    >
      {user.userMsg} ({user.userMsgType}){" "}
      <button
        onClick={(event) => handleReset(event, user.email)}
        style={{ float: "right" }}
      >
        Reset
      </button>
    </div>
  );

  const FeePercentage = ({ user, handleSetFeePercentage }) => {
    const [percentage, setPercentage] = useState(user?.feePercentage ?? "");
    return (
      <div>
        <input
          type="number"
          min={0}
          max={100}
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
        ></input>
        <button
          onClick={(e) => {
            handleSetFeePercentage(user, percentage);
          }}
          style={{ float: "right" }}
        >
          Set
        </button>
      </div>
    );
  };

  function applyFilters() {
    const inputValue = document.querySelector(".adminMessageSearchInput").value;
    const infoChecked = document.querySelector("#infoCheckbox").checked;
    const blockChecked = document.querySelector("#blockCheckbox").checked;

    setFilteredUsersList(
      mainUsersList
        .filter((user) => {
          let searchResults = user;
          if (inputValue.length > 0)
            searchResults = user?.email?.includes(inputValue);
          return searchResults;
        })
        .filter((user) => {
          let infoFilterResults = user;
          if (infoChecked)
            infoFilterResults = user.userMsgType === "Information";
          return infoFilterResults;
        })
        .filter((user) => {
          let blockFilterResults = user;
          if (blockChecked)
            blockFilterResults = user.userMsgType === "Blocking";
          return blockFilterResults;
        })
    );
  }

  function handleCheckUserSubscription(userEmail, index) {
    let userSubscriptionBox = document.querySelector(
      "#userSubscription-" +
        userEmail?.replaceAll("@", "")?.replaceAll(".", "") +
        index
    );
    userSubscriptionBox.textContent = "loading...";
    let a = 0;
    adminGetUserSubscriptionStatus(userEmail)
      .then((r) => {
        userSubscriptionBox.textContent = r.data.dataSubscription.plan;
        a = 1001;
      })
      .catch((e) => {
        userSubscriptionBox.textContent = "error, check again";
        a = 1001;
      });

    // // almost a working text spinner lol
    // for (a; a < 100; a++) {
    //   userSubscriptionBox.textContent = ".";
    //   setTimeout(() => {
    //     userSubscriptionBox.textContent = "..";
    //   }, 500);
    //   setTimeout(() => {
    //     userSubscriptionBox.textContent = "...";
    //   }, 500);
    // }
  }

  return (
    <div className="adminMessages">
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />
      <br />
      <div className="adminMessages-controls-wrapper">
        <InputComponent
          inputGroupClassName="dspInputGroup adminMessages-controls-input"
          inputClassName="dspInput adminMessageSearchInput"
          groupAddonClassName="dspInputAddon"
          inputGroupTextClassName="dspInputText"
          placeholder="Search by email"
          img={SearchIcon}
          imgClass="dspSearchIcon inputIcon"
          changeCallback={applyFilters}
          inputBlurCallback={() => {}}
          // inputUpperCommentClassName="inputUpperComment"
        />
        <input type="checkbox" id="infoCheckbox" onClick={applyFilters} />
        &nbsp; Information messages
        <br />
        <input type="checkbox" id="blockCheckbox" onClick={applyFilters} />
        &nbsp; Blocking messages
      </div>
      <br />
      <table className="adminTable">
        <thead>
          <tr>
            <th>First name</th>
            <th>Last name</th>
            <th>Email</th>
            <th>Subscription status</th>
            <th>User message</th>
            <th>Fee %</th>
            <th>Impersonate</th>
          </tr>
        </thead>

        <tbody>
          {filteredUsersList?.map((user, index) => {
            const id =
              user?.email?.replaceAll("@", "")?.replaceAll(".", "") + index;
            return (
              <tr key={id}>
                <td>{user.firstname}</td>
                <td>{user.lastname}</td>
                <td>
                  <a href={"mailto:" + user.email}>{user.email}</a>
                </td>
                <td>
                  <div
                    onClick={() =>
                      handleCheckUserSubscription(user.email, index)
                    }
                    className="cursor-pointer textLink"
                    id={"userSubscription-" + id}
                  >
                    <u>check</u>
                  </div>
                </td>
                <td>
                  {user.userMsg ? (
                    <MessageDisplay user={user} handleReset={handleReset} />
                  ) : (
                    <MessageSetter user={user} handleSet={handleSet} />
                  )}
                </td>
                <td>
                  <FeePercentage
                    user={user}
                    handleSetFeePercentage={handleSetFeePercentage}
                  />
                </td>
                <td>
                  <div
                    onClick={() =>
                      handleImpersonate(user)
                    }
                    className="cursor-pointer textLink"
                    id={"userSubscription-" + id}
                  >
                    <u>Impersonate</u>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UsersAdmin;
