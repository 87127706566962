import React, { useCallback, useEffect, useState } from "react";

import { getAllSubmittedReleases } from "../../routes/admin";

import { getDspList } from "../../routes/distribution";
import Drawer from "@material-ui/core/Drawer";
import InputComponent from "../form/InputComponent";
import SearchIcon from "../../assets/icons/search.svg";
import calendar from "../../assets/icons/calendar.svg";
import ReleasePlaceholder from "../../assets/releasePlaceholder.png";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import GridView from "./components/GridView";
import TableView from "../../screens/Admin/Releases/components/TableView";
import SelectComponent from "../form/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTh, faThList } from "@fortawesome/free-solid-svg-icons";
import ReleaseDetailsAdmin from "../../screens/Admin/Releases/ReleaseDetailsAdmin";
import { useQuery } from "react-query";
import ReactTooltip from "react-tooltip";
import { getJWT } from "../utils";
import CalendarComponent from "../form/CalendarComponent";
import moment from "moment";

const INITIAL_PAGE_SIZE = 10;

export const includes = (searchIn, searchBy) =>
  String(searchIn).toLowerCase().includes(String(searchBy).toLowerCase());

const customGlobalFilter = (rows, ids, filterValue) => {
  rows = rows.filter((row) => {
    return ids.some((id) => {
      let rowValue = row.values[id];

      if (id === "release") {
        const release = rowValue["release"];

        return (
          includes(release["#title"], filterValue) ||
          includes(release["upc"], filterValue) ||
          release["tracks"]?.some((track) => includes(track?.isrc, filterValue))
        );
      }

      return includes(rowValue, filterValue);
    });
  });

  return rows;
};

// Define a default UI for filtering
export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  className,
  searchCallback,
  setQueryPageIndex,
}) => {
  const count = preGlobalFilteredRows?.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    searchCallback && searchCallback(value);
    setQueryPageIndex && setQueryPageIndex(0);
  }, 200);

  return (
    <div
      className={
        className ? className : "col-12 col-md-4 col-lg-3 py-2 py-md-0"
      }
    >
      <InputComponent
        value={value || ""}
        inputGroupClassName="dspInputGroup admin-search"
        inputClassName="dspInput"
        groupAddonClassName="dspInputAddon"
        inputGroupTextClassName="dspInputText"
        placeholder={`Search in ${count} records...`}
        img={SearchIcon}
        imgClassName="dspSearchIcon inputIcon"
        changeCallback={(value) => {
          setValue(value);
          onChange(value);
        }}
        inputBlurCallback={() => {}}
        // inputUpperCommentClassName="inputUpperComment"
      />
    </div>
  );
};

const releaseStateFilterOptions = {
  "": { label: "All", value: "" },
  draft: { label: "Draft", value: "draft" },
  submitted: { label: "Submitted / In progress", value: "submitted" },
  submitted_to_qc: { label: "In quality control", value: "submitted_to_qc" },
  failed: { label: "Failed", value: "failed" },
  taken_down: { label: "Taken down", value: "taken_down" },
  succeeded: { label: "Succeeded", value: "succeeded" },
};

export const ReleaseStateFilter = ({
  setFilter,
  filters,
  options = releaseStateFilterOptions,
}) => {
  const initialReleaseStateFilter = filters.find(
    (filter) => filter.id === "releaseState"
  );
  const [releaseStateFilter, setReleaseStateFilter] = useState(
    initialReleaseStateFilter?.value || ""
  );

  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 py-2 py-md-0 text-white"
      }
      id={"releaseStateFilter"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">State</div>
      <SelectComponent
        value={options[releaseStateFilter].label}
        extendOptionWidth
        id={"releaseStateFilterSelect"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(options)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(val) => {
          setReleaseStateFilter(val);
          setFilter("releaseState", val);
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

const csvSentStateFilterOptions = {
  "": { label: "All", value: "" },
  true: { label: "Yes", value: "true" },
  false: { label: "No", value: "false" },
};
const CSVSentStateFilter = ({ setFilter, filters }) => {
  const CSVSentFilter = filters.find((filter) => filter.id === "csvSent");

  const [csvFilter, setCsvFilter] = useState(CSVSentFilter?.value || "");

  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 col-lg-2 py-2 py-md-0 text-white"
      }
      id={"csvSentFilter"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">CSV sent</div>
      <SelectComponent
        value={csvSentStateFilterOptions[csvFilter].label}
        id={"csvSentFilterSelect"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(csvSentStateFilterOptions)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(val) => {
          setCsvFilter(val);
          setFilter("csvSent", val);
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

const DateFilters = ({ setFilter, filters }) => {
  const [calendarState, setCalendarState] = useState({
    fromDate: false,
    untilDate: false,
  });
  const [pickedDate, setPickedDate] = useState({
    fromDate: "",
    untilDate: "",
  });

  useEffect(() => {
    setFilter("releaseApplicationDate", [
      pickedDate.fromDate,
      pickedDate.untilDate,
    ]);
  }, [pickedDate]);

  const handleOpenCalendar = useCallback((className, forField, id) => {
    setCalendarState((prevCalendarState) => ({
      ...prevCalendarState,
      [id]: !prevCalendarState[id],
    }));
  }, []);

  const datePickCallback = useCallback((date, calendarId, databaseField) => {
    setPickedDate((prevPickedState) => ({
      ...prevPickedState,
      [calendarId]: date,
    }));
  }, []);

  function inputChangeCallback(value, databaseField) {
    setPickedDate((prevPickedState) => ({
      ...prevPickedState,
      [databaseField]: value,
    }));
  }

  return (
    <div className="row mt-3">
      <div className="col col-lg-3">
        <div className="position-relative">
          <div
            className={
              "inputUpperComment iuc6 " + (pickedDate["fromDate"] || "d-none")
            }
          >
            From date
          </div>
          <InputComponent
            // disabled={true}
            value={pickedDate["fromDate"]} // take from calendar, or pre-populate
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup"
            inputClassName={
              "nrInput nrInput-opacityFix " +
              (pickedDate["fromDate"] || pickedDate["fromDate"].length
                ? "nrCalendarInputPopulated"
                : "")
            }
            commentField=".iuc6"
            placeholder="From date"
            addonClickCallback={handleOpenCalendar}
            for="nrReleaseDate"
            id="fromDate"
            // calendar addon
            addon="right"
            calendarAddon={true}
            calImg={calendar}
            calImgClass="nrAddonRightIcon"
            calAlt="pick date"
            inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
            databaseField="fromDate"
            inputBlurCallback={() => {}}
            changeCallback={inputChangeCallback}
          />
          <CalendarComponent
            calendarState={calendarState["fromDate"]}
            calendarClassName="nrReleaseDateCalendar"
            datePickCallback={datePickCallback}
            calendarId="fromDate"
            databaseField="fromDate"
            maxDate={
              pickedDate?.untilDate ? new Date(pickedDate?.untilDate) : ""
            }
            shouldCloseOnSelect={true}
          />
        </div>
      </div>{" "}
      <div className="col col-lg-3">
        <div className="position-relative">
          <div
            className={
              "inputUpperComment iuc6 " + (pickedDate["untilDate"] || "d-none")
            }
          >
            Until date
          </div>
          <InputComponent
            // disabled={true}
            value={pickedDate["untilDate"]} // take from calendar, or pre-populate
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup"
            inputClassName={
              "nrInput nrInput-opacityFix " +
              (pickedDate["untilDate"] || pickedDate["untilDate"].length
                ? "nrCalendarInputPopulated"
                : "")
            }
            commentField=".iuc6"
            placeholder="Until date"
            addonClickCallback={handleOpenCalendar}
            for="nrReleaseDate"
            id="untilDate"
            // calendar addon
            addon="right"
            calendarAddon={true}
            calImg={calendar}
            calImgClass="nrAddonRightIcon"
            calAlt="pick date"
            inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
            databaseField="untilDate"
            changeCallback={inputChangeCallback}
            inputBlurCallback={() => {}}
          />
          <CalendarComponent
            calendarState={calendarState["untilDate"]}
            calendarClassName="nrReleaseDateCalendar"
            datePickCallback={datePickCallback}
            calendarId="untilDate"
            databaseField="untilDate"
            minDate={pickedDate?.fromDate ? new Date(pickedDate?.fromDate) : ""}
            shouldCloseOnSelect={true}
            maxDate={new Date()}
          />
        </div>
      </div>
    </div>
  );
};

const statusCssClasses = {
  submitted: "info",
  submitted_to_qc: "warning",
  failed: "danger",
  taken_down: "light",
  succeeded: "success",
};

const defaultGlobalFilter = "";
const defaultFilters = [
  { id: "releaseState", value: "" },
  { id: "csvSent", value: "" },
];

const queryConfig = {
  refetchOnWindowFocus: false,
  cacheTime: 3_600_000, //milliseconds
  // refetchOnMount: false,
};

function MyReleases() {
  const jwtToken = getJWT();

  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    release: {},
  });
  const [view, setView] = useLocalStorage("adminReleasesViewType", true); // TODO: Change to view names 'grid-view' | 'table-view'

  // Releases query
  const { isLoading: areReleasesLoading, data: releasesResponse } = useQuery(
    ["adminAllReleases", jwtToken],
    getAllSubmittedReleases,
    queryConfig
  );

  // Platforms query
  const { data: platformsResponse } = useQuery(
    ["platforms", jwtToken],
    getDspList,
    queryConfig
  );

  const releases = React.useMemo(
    () => releasesResponse?.data.reverse() ?? [],
    [releasesResponse]
  );

  const platforms =
    platformsResponse?.data.filter((platform) => !platform.disabled) ?? [];

  const columns = React.useMemo(
    () => [
      {
        Header: "Primary artist",
        accessor: (originalRow) => originalRow,
        id: "release.#participants",
        Cell: ({ value }) => {
          return (
            <div>
              {value.release["#participants"]
                .filter((participant) => participant.primary)
                .map((participant, index, arr) => (
                  <div>
                    {participant.primary}
                    {index != arr.length - 1 ? ", " : ""}
                  </div>
                ))}
            </div>
          );
        },
      },

      {
        Header: "Release",
        accessor: (originalRow) => originalRow,
        id: "release",
        Cell: ({ value }) => (
          <div className="playlistsContainer align-items-center" title="">
            <img
              width="50"
              height="50"
              className="rounded"
              loading="lazy"
              src={
                value.release["#cover_url"] === "" ||
                value.release["#cover_url"] === "initial"
                  ? ReleasePlaceholder
                  : value.release["#cover_url"]
              }
            />
            <div className="pl-2 text-white">
              <div className="spotifycharts-title pb-1">
                Release:&nbsp;
                <span className="text-white">{value.release["#title"]}</span>
              </div>
              <div className="spotifycharts-title pb-1">
                Remix/Version:&nbsp;
                <span className="text-white">
                  {value.release["#remix_or_version"]}
                </span>
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: "User mail",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) => {
          return (
            <div>
              <a href={"mailto:" + value}>
                <u>{value.email}</u>
              </a>
              <div className="spotifycharts-title pb-1">
                User:&nbsp;
                <span className="text-white">{`${value?.firstname || ""} ${
                  value?.lastname || ""
                }`}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Label",
        accessor: "release.#label",
      },
      {
        Header: "Primary genre",
        accessor: "release.#primary_genre",
      },
      {
        Header: "Release date",
        accessor: "release.#digital_release",
      },
      {
        id: "releaseState",
        Header: "Status",
        accessor: "release.state",
        disableSortBy: true,
        filter: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id] || false;

            if (filterValue === "") return true;

            return rowValue === filterValue;
          });
        },
        Cell: ({ value }) => (
          <span
            className={`font-weight-bold fs-14 badge badge-${statusCssClasses[value]}`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "",
        id: "more-info",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) => (
          <div
            className="admin-release-details-link"
            onClick={toggleDrawer(true, value)}
          >
            Details
          </div>
        ),
      },
      {
        id: "csvSent",
        accessor: "release.csvSent",
        filter: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id] || false;

            if (filterValue === "") return true;

            return rowValue === JSON.parse(filterValue);
          });
        },
      },
      {
        id: "user",
        accessor: "email",
      },
      {
        id: "firstname",
        accessor: "firstname",
      },
      {
        id: "lastname",
        accessor: "lastname",
      },
      {
        id: "releaseApplicationDate",
        accessor: "releaseApplicationDate",
        filter: "date",
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      date: (rows, id, filterValue) => {
        const startDateFilter = filterValue[0];
        const endDateFilter = filterValue[1];

        if (startDateFilter && !endDateFilter) {
          return rows.filter((row) => {
            const rowValue = row.original.release[id];
            if (!rowValue) return false;
            const parsedStartFilter = moment(filterValue[0]);

            return moment(rowValue, "YYYY-MM-DD").isSameOrAfter(
              parsedStartFilter
            );
          });
        }

        if (!startDateFilter && endDateFilter) {
          return rows.filter((row) => {
            const rowValue = row.original.release[id];
            if (!rowValue) return false;

            const parsedEndFilter = moment(filterValue[1]).add(1, "day");

            return moment(rowValue, "YYYY-MM-DD").isSameOrBefore(
              parsedEndFilter
            );
          });
        }

        if (startDateFilter && endDateFilter) {
          return rows.filter((row) => {
            const rowValue = row.original.release[id];
            if (!rowValue) return false;
            const parsedStartFilter = moment(filterValue[0]);
            const parsedEndFilter = moment(filterValue[1]);

            return moment(rowValue, "YYYY-MM-DD").isBetween(
              parsedStartFilter,
              parsedEndFilter,
              "date",
              "[]"
            );
          });
        }

        return rows;
      },
    }),
    []
  );

  // Table instantiation
  const tableInstance = useTable(
    {
      columns,
      data: releases,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: [
          "csvSent",
          "user",
          "firstname",
          "lastname",
          "releaseApplicationDate",
        ],
        filters: defaultFilters,
        globalFilter: defaultGlobalFilter,
      },
      globalFilter: customGlobalFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  const toggleDrawer =
    (isOpen, release = {}) =>
    (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setDrawerState({ isOpen, release });
    };

  return (
    <div className="allowUserSelect">
      <main
        className={`admin-releases mt-4 ${!view ? "pb-3" : ""}`}
        style={{ marginLeft: "0" }}
      >
        <div className="d-block mb-3" style={{ zIndex: 1 }}>
          <p className="font-weight-bold text-white mb-0">Filter by:</p>
          <div className="row pb-2 align-items-center justify-content-between">
            <div class="col-12 col-md">
              <div class="row">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <ReleaseStateFilter setFilter={setFilter} filters={filters} />
                <CSVSentStateFilter setFilter={setFilter} filters={filters} />
              </div>
              <DateFilters setFilter={setFilter} filters={filters} />
            </div>
            <div className="pr-md-4 pt-2 pl-3 col-12 col-md-auto text-right ">
              <span
                className={`mr-2 cursor-pointer text-${
                  view ? "primary" : "white"
                }`}
                onClick={() => setView(true)}
                data-for="view-type"
                data-tip="Grid view"
              >
                <FontAwesomeIcon
                  icon={faTh}
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  className="mr-2"
                />
              </span>
              <span
                className={`cursor-pointer text-${!view ? "primary" : "white"}`}
                onClick={() => setView(false)}
                data-for="view-type"
                data-tip="Table view"
              >
                <FontAwesomeIcon
                  icon={faThList}
                  style={{ width: "24px", height: "24px" }}
                  className="mr-2"
                />
              </span>
            </div>
          </div>
        </div>
        {view ? (
          <div className="row justify-content-center" style={{ gap: "4%" }}>
            <GridView
              tableInstance={tableInstance}
              toggleDrawer={toggleDrawer}
              loading={areReleasesLoading}
            />
          </div>
        ) : (
          <TableView
            tableInstance={tableInstance}
            loading={areReleasesLoading}
          />
        )}
      </main>
      <Drawer
        anchor={window.innerWidth < 768 ? "bottom" : "right"}
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        className="h-100"
      >
        <div className={`bg-dark admin-release-drawer`} role="presentation">
          <div
            onClick={toggleDrawer(false)}
            className="row mx-0 justify-content-start align-items-center text-white px-3 pt-3"
          >
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "20px", height: "20px" }}
                className="mr-2"
              />
              <span>Close</span>
            </div>
          </div>
          <ReleaseDetailsAdmin
            platforms={platforms}
            release={drawerState?.release}
            onUpdate={setDrawerState}
          />
        </div>
      </Drawer>
      <ReactTooltip
        place="top"
        backgroundColor="#1d2025"
        className="ptTooltip"
        border
        borderColor="#0ef1db"
        effect="float"
        id="view-type"
      />
    </div>
  );
}

export default MyReleases;
